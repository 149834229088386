<template>
    <div>
        <div class="payment-links-group">
            <a-alert
                class="mb-20 f12"
                type="info"
                message="Selecione as PDQs que ficarão ativas para o link de pagamento por empresa:"
                banner
            />

            <div
                class="group mb-20"
                v-for="(group, index) in listCardMachinesByGroup"
                :key="index"
            >
                <div class="title">{{ group.name }}</div>

                <div class="options">
                    <a-radio-group
                        v-model="group.selected"
                        @change="onSelectCardMachine(group.name)"
                    >
                        <a-radio
                            v-for="(option, index) in group.data"
                            :key="index"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import _ from "lodash";

export default {
    name: "PaymentLinkCardMachineModal",
    mixins: [cardMachinesMixins],
    data() {
        return {
            listCardMachinesByGroup: [],
        };
    },
    beforeMount() {
        this.cardMachines.pagination.perPage = 200;
        this.cardMachines.filters.paymentLinkStatus.selected = ["Ativo"];
        this.getCardMachines();
    },
    methods: {
        onSelectCardMachine(groupName) {
            console.log(groupName, this.listCardMachinesByGroup);

            this.listCardMachinesByGroup.forEach((machineGroup) => {
                if (machineGroup.name === groupName) {
                    machineGroup.data.forEach((machine) => {
                        if (machineGroup.selected == machine.raw.id) {
                            machine.raw.payment_link_active_machine = true;
                            machine.raw.action = `activate-payment-link`;
                            machine.raw.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} ativou a PDQ ID ${machine.id} para Link de Pagamentos no contrato.`;
                        } else {
                            machine.raw.payment_link_active_machine = false;
                            machine.raw.action = `deactivate-payment-link`;
                            machine.raw.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} desativou a PDQ ID ${machine.id} para Link de Pagamentos no contrato.`;
                        }

                        machine.raw.modified_by = {
                            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                            id: this.$store.state.userData.id,
                        };

                        this.$http
                            .post("/card-machine/update", machine.raw)
                            .then(({ data }) => {
                                data;
                                if (machineGroup.selected == machine.raw.id) {
                                    this.$message.success(
                                        "Atualizado com sucesso!"
                                    );
                                }
                            });
                    });
                }
            });
        },
        updatePaymentLinkPDQs() {
            console.log(this.listCardMachinesByGroup);
        },
        cardMachinesByCompany() {
            let arr = this.cardMachines.list,
                newArr = [],
                newNewArrKeys = [],
                newNewArrValues = [],
                lastArr = [];

            newArr = _.groupBy(arr, (group) => group.company.trading_name);

            newNewArrKeys = Object.keys(newArr); //['PRUDENTE ', 'FEDERAL']
            newNewArrValues = Object.values(newArr);

            console.log("newNewArrKeys", newNewArrKeys);

            for (var i = 0; i < newNewArrKeys.length; i++) {
                let selectedVal = newNewArrValues[i].filter((machine) => {
                    return machine.payment_link_active_machine;
                });

                lastArr.push({
                    name: newNewArrKeys[i],
                    selected:
                        selectedVal.length > 0 ? selectedVal[0].id : undefined,
                    data: newNewArrValues[i],
                });
                newNewArrValues[i].forEach((machine) => {
                    machine;
                });
            }

            this.listCardMachinesByGroup = lastArr;
        },
    },
};
</script>

<style lang="sass" scoped>
.payment-links-group
  .group
    border-bottom: 1px solid #ddd
    padding: 0 0  10px
    .ant-radio-group
      width: 100%
      label
        display: block
        padding: 7px 10px
        margin-bottom: 10px
        border-radius: 6px
        border: 0
        font-size: 12px
        color: #333
        font-weight: 500
      & .ant-radio-wrapper-checked
        background: #fafafa
        color: #333
  .title
    margin-bottom: 10px
    font-size: 12px
    font-weight: 600
</style>
